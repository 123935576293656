import React from 'react';

export const Logo = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 906 194"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="logo-main"
      d="M474.8 41.2035H500.401C500.933 41.2035 501.408 41.5268 501.607 42.018L530.376 112.775C530.517 113.124 530.857 113.357 531.235 113.357C531.613 113.357 531.953 113.124 532.094 112.775L560.863 42.018C561.062 41.5268 561.543 41.2035 562.069 41.2035H587.67C588.388 41.2035 588.978 41.7918 588.978 42.5158V147.274C588.978 147.998 588.394 148.593 587.67 148.593H568.431C567.712 148.593 567.129 148.005 567.129 147.274V78.6893C566.596 78.6893 566.115 79.019 565.917 79.5167L539.007 147.242C538.809 147.74 538.328 148.069 537.795 148.069H524.687C524.155 148.069 523.674 147.74 523.475 147.242L496.56 79.2646C496.361 78.7669 495.88 78.4372 495.348 78.4372V147.281C495.348 148.005 494.764 148.599 494.039 148.599H474.8C474.082 148.599 473.498 148.011 473.498 147.281V42.5158C473.498 41.7918 474.082 41.2035 474.8 41.2035Z"
      fill="#022E31"
    />
    <path
      className="logo-main"
      d="M675.753 41.2035H696.262C696.775 41.2035 697.191 41.6237 697.191 42.1409V112.2C697.191 120.312 695.3 127.19 691.522 132.833C687.739 138.477 682.531 142.769 675.887 145.703C669.243 148.638 661.625 150.106 653.025 150.106C644.425 150.106 636.793 148.638 630.137 145.703C623.48 142.769 618.266 138.477 614.502 132.833C610.737 127.19 608.858 120.312 608.858 112.2V42.1409C608.858 41.6237 609.275 41.2035 609.788 41.2035H630.451C630.964 41.2035 631.381 41.6237 631.381 42.1409V111.417C631.381 117.151 633.433 121.663 637.544 124.947C641.655 128.231 646.811 129.873 653.018 129.873C659.329 129.873 664.543 128.231 668.653 124.947C672.764 121.663 674.816 117.151 674.816 111.417V42.1409C674.816 41.6237 675.233 41.2035 675.753 41.2035Z"
      fill="#022E31"
    />
    <path
      className="logo-main"
      d="M715.706 147.656V42.1409C715.706 41.6237 716.123 41.2035 716.636 41.2035H737.299C737.812 41.2035 738.228 41.6237 738.228 42.1409V128.935C738.228 129.453 738.645 129.873 739.158 129.873H782.972C783.485 129.873 783.902 130.293 783.902 130.81V147.656C783.902 148.173 783.485 148.593 782.972 148.593H716.636C716.123 148.593 715.706 148.173 715.706 147.656Z"
      fill="#022E31"
    />
    <path
      className="logo-main"
      d="M781.516 58.9865V42.1409C781.516 41.6237 781.933 41.2035 782.446 41.2035H868.086C868.599 41.2035 869.016 41.6237 869.016 42.1409V58.9865C869.016 59.5036 868.599 59.9238 868.086 59.9238H837.329C836.816 59.9238 836.399 60.344 836.399 60.8611V147.656C836.399 148.173 835.982 148.593 835.469 148.593H815.063C814.55 148.593 814.133 148.173 814.133 147.656V60.8611C814.133 60.344 813.716 59.9238 813.203 59.9238H782.446C781.933 59.9238 781.516 59.5036 781.516 58.9865Z"
      fill="#022E31"
    />
    <path
      className="logo-main"
      d="M906 42.1409V147.656C906 148.173 905.583 148.593 905.07 148.593H884.407C883.894 148.593 883.477 148.173 883.477 147.656V42.1409C883.477 41.6237 883.894 41.2035 884.407 41.2035H905.07C905.583 41.2035 906 41.6237 906 42.1409Z"
      fill="#022E31"
    />
    <g clipPath="url(#clip0)">
      <path
        className="logo-accent"
        d="M265.024 182.736C264.24 182.736 263.443 182.605 262.659 182.33C258.909 181.019 256.936 176.908 258.242 173.144L312.813 16.0891C314.119 12.3258 318.215 10.3392 321.965 11.6505C325.715 12.9617 327.688 17.0725 326.382 20.8358L271.811 177.891C270.773 180.874 267.996 182.736 265.024 182.736Z"
        fill="#0E6D62"
      />
      <path
        className="logo-accent"
        d="M342.126 153.377C340.049 153.377 337.991 152.479 336.566 150.742C334.045 147.667 334.489 143.117 337.559 140.593L390.444 96.9935L337.559 53.3943C334.495 50.8636 334.051 46.3201 336.566 43.2452C339.088 40.1637 343.616 39.7244 346.679 42.2486L406.149 91.2764C407.809 92.6467 408.776 94.6922 408.776 96.8492V97.1377C408.776 99.2947 407.815 101.34 406.149 102.711L346.679 151.738C345.347 152.84 343.733 153.377 342.126 153.377Z"
        fill="#0E6D62"
      />
      <path
        className="logo-accent"
        d="M66.6561 153.368C65.0489 153.368 63.4353 152.83 62.096 151.729L2.62626 102.705C0.966882 101.335 0 99.2898 0 97.133V96.8445C0 94.6877 0.960349 92.6423 2.62626 91.2721L62.096 42.2484C65.16 39.7179 69.6874 40.1637 72.2091 43.2449C74.7308 46.3195 74.2866 50.8692 71.2161 53.3932L18.3316 96.9887L71.2161 140.584C74.2801 143.115 74.7243 147.658 72.2091 150.733C70.7914 152.47 68.727 153.368 66.6561 153.368Z"
        fill="#0E6D62"
      />
      <path
        className="logo-main"
        d="M124.068 152.741C114.262 152.741 105.018 146.834 101.451 137.354L83.9881 90.8961C81.7995 85.0676 82.0347 78.7474 84.6609 73.0893C87.3656 67.2673 92.2392 62.8681 98.3933 60.698C110.793 56.3249 124.577 62.6058 129.124 74.6956L133.064 85.1856C134.207 88.2343 132.678 91.637 129.641 92.7909C126.603 93.9448 123.212 92.4041 122.062 89.3554L118.123 78.8654C115.817 72.7287 108.715 69.5751 102.293 71.8371C99.1511 72.9451 96.6751 75.1611 95.3228 78.0786C94.0423 80.8257 93.9313 83.9006 94.9896 86.7329L112.452 133.191C114.758 139.327 121.866 142.481 128.282 140.219C131.424 139.111 133.9 136.895 135.252 133.977C136.533 131.23 136.644 128.155 135.586 125.323L128.857 107.424C127.713 104.376 129.242 100.973 132.28 99.8192C135.318 98.6719 138.708 100.213 139.858 103.255L146.587 121.153C148.776 126.982 148.54 133.302 145.914 138.96C143.21 144.782 138.336 149.181 132.182 151.352C129.503 152.296 126.766 152.741 124.068 152.741Z"
        fill="#022E31"
      />
      <path
        className="logo-main"
        d="M194.925 104.422C184.446 104.422 174.79 102.304 167.192 98.1408C166.826 97.9375 158.046 93.0466 148.057 80.3077C146.652 78.5179 145.705 76.433 145.313 74.276C144.405 69.2014 146.581 63.8908 150.866 60.7373C154.048 58.3901 157.523 57.9377 159.307 57.8722C160.182 57.8394 161.28 57.8132 162.586 57.7804C170.759 57.5837 187.765 57.1707 210.003 53.7548C213.211 53.2631 216.216 55.4726 216.706 58.6917C217.196 61.9108 214.994 64.9267 211.786 65.4185C188.803 68.9523 171.288 69.3784 162.867 69.5817C161.62 69.6145 160.568 69.6341 159.732 69.6669C159.294 69.68 158.405 69.8243 157.824 70.2504C157.144 70.7487 156.772 71.5289 156.89 72.1845C156.922 72.3681 157.02 72.6566 157.295 73.0106C165.663 83.6777 172.791 87.7622 172.863 87.8016C183.028 93.3744 199.119 94.1611 214.811 89.8471C217.94 88.9817 221.181 90.8371 222.037 93.9776C222.892 97.118 221.05 100.363 217.921 101.229C210.133 103.36 202.32 104.422 194.925 104.422Z"
        fill="#022E31"
      />
      <path
        className="logo-main"
        d="M195.63 155.869C193.233 155.869 190.979 154.38 190.103 151.987C185.877 140.35 185.596 129.145 189.267 118.682C193.024 107.988 201.04 98.3506 212.459 90.8043C215.17 89.0145 218.816 89.7685 220.606 92.4893C222.389 95.2102 221.638 98.8685 218.927 100.665C209.546 106.867 203.3 114.25 200.367 122.602C197.623 130.417 197.884 138.947 201.157 147.942C202.268 151.004 200.7 154.394 197.649 155.508C196.983 155.751 196.297 155.869 195.63 155.869Z"
        fill="#022E31"
      />
      <path
        className="logo-main"
        d="M257.393 73.9941L245.653 73.3319C245.666 73.1156 245.889 67.982 241.538 64.5269C238.232 61.9044 234.377 61.865 233.143 61.9371C230.164 62.5206 227.139 63.0648 224.14 63.5631C220.521 64.1597 216.836 64.7039 213.197 65.1694L211.701 53.4664C215.203 53.0141 218.744 52.4961 222.226 51.9192C225.225 51.4209 228.249 50.8767 231.222 50.2866L231.529 50.2276L231.843 50.2014C234.711 49.9457 242.112 49.9326 248.835 55.276C257.589 62.2322 257.498 72.0862 257.393 73.9941Z"
        fill="#022E31"
      />
      <path
        className="logo-main"
        d="M179.912 194C179.461 194 179.017 193.993 178.566 193.987C142.021 193.384 109.46 167.827 99.3863 131.833L110.708 128.641C119.384 159.652 147.365 181.668 178.756 182.186C197.048 182.507 214.125 175.583 226.904 162.753C239.963 149.634 246.98 131.919 246.646 112.879C246.627 111.902 246.62 111.581 246.568 110.637L258.308 109.968C258.373 111.083 258.386 111.568 258.399 112.67C258.785 134.935 250.553 155.685 235.22 171.092C220.495 185.896 200.896 194 179.912 194Z"
        fill="#022E31"
      />
      <path
        className="logo-main"
        d="M127.772 93.1122C125.394 93.1122 123.16 91.6567 122.271 89.2899L111.681 61.1176C109.493 55.2891 109.728 48.9688 112.354 43.3173C115.059 37.4954 119.933 33.0961 126.087 30.926C137.761 26.8086 150.951 32.2176 156.106 43.2386C157.484 46.189 156.223 49.7031 153.283 51.0865C150.344 52.4764 146.842 51.2045 145.463 48.2542C142.837 42.6486 136.036 39.9277 129.987 42.0585C126.844 43.1665 124.368 45.3825 123.016 48.3001C121.742 51.0472 121.625 54.1221 122.689 56.9544L133.273 85.1267C134.416 88.1753 132.887 91.578 129.85 92.7319C129.164 92.9876 128.458 93.1122 127.772 93.1122Z"
        fill="#022E31"
      />
      <path
        className="logo-main"
        d="M153.251 126.936C143.445 126.936 134.201 121.029 130.634 111.548L129.046 107.333C127.903 104.284 129.432 100.881 132.469 99.7274C135.507 98.5801 138.898 100.114 140.048 103.163L141.635 107.385C143.941 113.522 151.043 116.675 157.465 114.413C160.607 113.305 163.083 111.089 164.435 108.172C165.716 105.425 165.827 102.35 164.768 99.5176L162.743 94.1218C161.6 91.0732 163.129 87.6705 166.173 86.5166C169.211 85.3692 172.602 86.9099 173.751 89.9586L175.777 95.3544C177.965 101.183 177.73 107.503 175.104 113.161C172.399 118.983 167.525 123.382 161.371 125.546C158.686 126.49 155.949 126.936 153.251 126.936Z"
        fill="#022E31"
      />
      <path
        className="logo-main"
        d="M151.03 51.5455C148.652 51.5455 146.417 50.09 145.529 47.7232L142.961 40.8915C140.773 35.063 141.008 28.7427 143.634 23.0847C146.339 17.2627 151.212 12.8635 157.367 10.6999C169.766 6.33341 183.551 12.6078 188.098 24.6975L193.821 39.9277C194.964 42.9764 193.435 46.3791 190.397 47.533C187.36 48.6804 183.969 47.1462 182.819 44.0975L177.096 28.8673C174.79 22.7306 167.689 19.5771 161.267 21.839C158.124 22.947 155.648 25.163 154.296 28.0806C153.016 30.8276 152.905 33.9025 153.963 36.7348L156.53 43.5665C157.674 46.6151 156.145 50.0178 153.107 51.1718C152.421 51.4209 151.716 51.5455 151.03 51.5455Z"
        fill="#022E31"
      />
      <path
        className="logo-main"
        d="M231.32 59.3802C228.622 59.3802 226.192 57.5051 225.584 54.7514C225.466 54.2269 225.31 53.7024 225.12 53.191L212.479 19.5639C210.173 13.4273 203.071 10.2737 196.649 12.5356C193.507 13.6436 191.031 15.8596 189.679 18.7772C188.405 21.5243 188.287 24.5991 189.352 27.4315L194.03 39.8687C195.173 42.9174 193.644 46.3201 190.6 47.474C187.562 48.6213 184.171 47.0872 183.022 44.0319L178.344 31.5947C176.155 25.7662 176.391 19.4459 179.017 13.7944C181.722 7.97245 186.595 3.57319 192.749 1.40307C205.149 -2.96996 218.933 3.31094 223.48 15.4007L236.122 49.0278C236.514 50.0703 236.827 51.1389 237.069 52.2142C237.768 55.3939 235.762 58.5475 232.594 59.249C232.163 59.3343 231.738 59.3802 231.32 59.3802Z"
        fill="#022E31"
      />
      <path
        className="logo-main"
        d="M257.408 73.4996L245.652 73.797L246.589 111.104L258.345 110.807L257.408 73.4996Z"
        fill="#022E31"
      />
      <path
        className="logo-main"
        d="M257.413 72.9123H245.653V74.8792H257.413V72.9123Z"
        fill="#022E31"
      />
      <path
        className="logo-main"
        d="M211.368 65.4054C208.494 65.4054 205.978 63.2812 205.554 60.344C205.09 57.1183 207.324 54.1286 210.538 53.6631C216.836 52.7583 219.417 52.365 219.436 52.365C222.651 51.8798 225.643 54.0958 226.126 57.3215C226.61 60.5472 224.402 63.55 221.187 64.0351C221.187 64.0351 218.587 64.4285 212.211 65.3464C211.93 65.3857 211.649 65.4054 211.368 65.4054Z"
        fill="#022E31"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="408.776" height="194" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const Logomark = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 499 235"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M323.519 221.356C322.562 221.356 321.589 221.197 320.632 220.863C316.055 219.275 313.646 214.296 315.241 209.737L381.856 19.4893C383.451 14.9307 388.451 12.5243 393.029 14.1127C397.607 15.701 400.015 20.6806 398.42 25.2392L331.805 215.487C330.537 219.1 327.148 221.356 323.519 221.356Z"
        fill="#0E6D62"
      />
      <path
        d="M417.64 185.792C415.104 185.792 412.591 184.704 410.853 182.6C407.775 178.875 408.317 173.363 412.065 170.305L476.622 117.492L412.065 64.6786C408.325 61.613 407.783 56.1093 410.853 52.3846C413.931 48.6519 419.458 48.1198 423.198 51.1774L495.794 110.567C497.82 112.227 499 114.704 499 117.317V117.667C499 120.28 497.828 122.758 495.794 124.417L423.198 183.807C421.571 185.141 419.601 185.792 417.64 185.792Z"
        fill="#0E6D62"
      />
      <path
        d="M81.3683 185.781C79.4065 185.781 77.4367 185.13 75.8018 183.795L3.20593 124.411C1.18029 122.751 0 120.274 0 117.661V117.312C0 114.699 1.17232 112.221 3.20593 110.562L75.8018 51.1772C79.542 48.1119 85.0687 48.6519 88.147 52.3843C91.2253 56.1087 90.683 61.6199 86.9348 64.6773L22.3777 117.486L86.9348 170.295C90.6751 173.361 91.2173 178.864 88.147 182.588C86.4164 184.693 83.8964 185.781 81.3683 185.781Z"
        fill="#0E6D62"
      />
      <path
        d="M151.452 185.022C139.482 185.022 128.197 177.866 123.843 166.382L102.526 110.106C99.8542 103.046 100.141 95.3898 103.347 88.536C106.649 81.4836 112.598 76.1546 120.111 73.5259C135.247 68.2286 152.074 75.8369 157.625 90.4818L162.434 103.189C163.829 106.882 161.963 111.004 158.255 112.401C154.546 113.799 150.407 111.933 149.004 108.24L144.195 95.5328C141.38 88.0992 132.711 84.2791 124.872 87.0191C121.036 88.3613 118.013 91.0456 116.362 94.5798C114.799 97.9074 114.664 101.632 115.956 105.063L137.273 161.339C140.088 168.773 148.764 172.593 156.596 169.853C160.432 168.511 163.454 165.826 165.105 162.292C166.668 158.965 166.804 155.24 165.512 151.809L157.298 130.128C155.902 126.435 157.768 122.313 161.477 120.915C165.185 119.525 169.324 121.392 170.727 125.077L178.942 146.758C181.613 153.818 181.326 161.474 178.12 168.328C174.819 175.38 168.869 180.709 161.357 183.338C158.087 184.482 154.746 185.022 151.452 185.022Z"
        fill="#022E31"
      />
      <path
        d="M237.948 126.49C225.156 126.49 213.369 123.925 204.095 118.882C203.648 118.636 192.93 112.711 180.736 97.28C179.021 95.1119 177.865 92.5863 177.387 89.9735C176.278 83.8265 178.934 77.3935 184.165 73.5735C188.049 70.7303 192.292 70.1823 194.469 70.1029C195.538 70.0632 196.877 70.0314 198.472 69.9917C208.449 69.7535 229.208 69.2531 256.354 65.1154C260.27 64.5198 263.939 67.1962 264.537 71.0956C265.135 74.9951 262.447 78.6484 258.532 79.244C230.476 83.5247 209.095 84.0409 198.815 84.2871C197.292 84.3268 196.008 84.3506 194.987 84.3903C194.453 84.4062 193.368 84.5809 192.659 85.0972C191.829 85.7007 191.375 86.6458 191.518 87.44C191.558 87.6624 191.678 88.0118 192.013 88.4407C202.229 101.362 210.929 106.31 211.017 106.358C223.426 113.108 243.068 114.061 262.224 108.835C266.044 107.787 270 110.035 271.044 113.839C272.089 117.643 269.84 121.574 266.02 122.622C256.514 125.204 246.976 126.49 237.948 126.49Z"
        fill="#022E31"
      />
      <path
        d="M238.81 188.81C235.883 188.81 233.131 187.007 232.063 184.108C226.903 170.012 226.56 156.439 231.042 143.764C235.628 130.811 245.413 119.136 259.353 109.995C262.663 107.827 267.113 108.74 269.298 112.036C271.475 115.332 270.558 119.763 267.248 121.94C255.796 129.453 248.172 138.395 244.591 148.513C241.242 157.98 241.561 168.312 245.556 179.208C246.912 182.917 244.998 187.023 241.274 188.373C240.46 188.667 239.623 188.81 238.81 188.81Z"
        fill="#022E31"
      />
      <path
        d="M314.205 89.6321L299.874 88.8299C299.89 88.5678 300.161 82.3494 294.849 78.164C290.814 74.9872 286.109 74.9396 284.602 75.0269C280.965 75.7338 277.273 76.3929 273.612 76.9965C269.194 77.7192 264.696 78.3784 260.254 78.9423L258.428 64.766C262.702 64.2181 267.025 63.5906 271.275 62.8918C274.936 62.2882 278.628 61.629 282.257 60.9142L282.632 60.8428L283.015 60.811C286.516 60.5013 295.551 60.4854 303.757 66.958C314.444 75.3843 314.332 87.321 314.205 89.6321Z"
        fill="#022E31"
      />
      <path
        d="M219.622 235C219.072 235 218.529 234.992 217.979 234.984C173.367 234.254 133.62 203.296 121.323 159.695L135.143 155.828C145.734 193.393 179.891 220.061 218.21 220.689C240.54 221.078 261.387 212.691 276.986 197.149C292.928 181.257 301.493 159.798 301.086 136.735C301.062 135.552 301.054 135.163 300.99 134.019L315.321 133.209C315.401 134.559 315.417 135.147 315.433 136.481C315.903 163.452 305.855 188.588 287.138 207.251C269.162 225.184 245.237 235 219.622 235Z"
        fill="#022E31"
      />
      <path
        d="M155.974 112.791C153.071 112.791 150.343 111.027 149.259 108.16L136.332 74.0342C133.66 66.9739 133.947 59.3179 137.153 52.472C140.455 45.4196 146.404 40.0906 153.916 37.4619C168.167 32.4744 184.269 39.0264 190.561 52.3767C192.244 55.9506 190.705 60.2074 187.116 61.8831C183.527 63.5668 179.253 62.0261 177.57 58.4522C174.364 51.6619 166.062 48.3661 158.677 50.9472C154.841 52.2894 151.819 54.9737 150.168 58.5078C148.613 61.8355 148.469 65.5602 149.769 68.9911L162.689 103.117C164.084 106.81 162.218 110.932 158.51 112.33C157.672 112.64 156.811 112.791 155.974 112.791Z"
        fill="#022E31"
      />
      <path
        d="M187.076 153.763C175.106 153.763 163.821 146.607 159.467 135.123L157.529 130.016C156.133 126.323 158 122.202 161.708 120.804C165.416 119.414 169.555 121.272 170.959 124.965L172.897 130.08C175.712 137.514 184.381 141.334 192.22 138.594C196.056 137.251 199.078 134.567 200.729 131.033C202.292 127.705 202.428 123.981 201.136 120.55L198.664 114.014C197.268 110.321 199.134 106.199 202.851 104.801C206.559 103.411 210.698 105.278 212.102 108.97L214.574 115.507C217.245 122.567 216.958 130.223 213.752 137.077C210.451 144.129 204.501 149.458 196.989 152.079C193.711 153.223 190.37 153.763 187.076 153.763Z"
        fill="#022E31"
      />
      <path
        d="M184.365 62.4391C181.462 62.4391 178.734 60.676 177.65 57.809L174.516 49.5335C171.844 42.4732 172.131 34.8172 175.337 27.9634C178.639 20.911 184.588 15.582 192.1 12.9612C207.237 7.67192 224.064 15.2723 229.614 29.9171L236.6 48.3661C237.996 52.0591 236.13 56.1809 232.422 57.5787C228.713 58.9685 224.574 57.1101 223.171 53.4171L216.185 34.9681C213.369 27.5346 204.701 23.7145 196.861 26.4545C193.025 27.7966 190.003 30.481 188.352 34.0151C186.789 37.3428 186.653 41.0675 187.945 44.4984L191.08 52.7738C192.475 56.4668 190.609 60.5886 186.901 61.9864C186.063 62.2882 185.202 62.4391 184.365 62.4391Z"
        fill="#022E31"
      />
      <path
        d="M282.377 71.9296C279.083 71.9296 276.116 69.6582 275.375 66.3226C275.231 65.6873 275.04 65.0519 274.808 64.4324L259.377 23.6986C256.562 16.265 247.893 12.4449 240.054 15.1849C236.218 16.5271 233.195 19.2114 231.544 22.7456C229.989 26.0732 229.846 29.7979 231.146 33.2288L236.856 48.2946C238.251 51.9875 236.385 56.1094 232.669 57.5071C228.961 58.897 224.822 57.0386 223.418 53.3377L217.708 38.2719C215.036 31.2116 215.323 23.5556 218.529 16.7097C221.831 9.65735 227.78 4.32835 235.293 1.69959C250.429 -3.59763 267.256 4.01068 272.807 18.6555L288.238 59.3894C288.717 60.6521 289.1 61.9466 289.395 63.2491C290.248 67.1009 287.8 70.921 283.932 71.7707C283.405 71.874 282.887 71.9296 282.377 71.9296Z"
        fill="#022E31"
      />
      <path
        d="M314.223 89.033L299.872 89.3933L301.016 134.585L315.367 134.225L314.223 89.033Z"
        fill="#022E31"
      />
      <path
        d="M314.229 88.3216H299.874V90.7042H314.229V88.3216Z"
        fill="#022E31"
      />
      <path
        d="M258.021 79.2282C254.512 79.2282 251.442 76.655 250.923 73.0971C250.357 69.1897 253.085 65.5682 257.008 65.0043C264.696 63.9083 267.846 63.4318 267.87 63.4318C271.794 62.8441 275.446 65.5285 276.036 69.4359C276.627 73.3433 273.931 76.9807 270.007 77.5684C270.007 77.5684 266.833 78.0449 259.05 79.1567C258.707 79.2044 258.364 79.2282 258.021 79.2282Z"
        fill="#022E31"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="499" height="235" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
