const navigation = [
  {
    label: 'Home',
    link: '/',
  },
  {
    label: 'Team',
    link: '/team',
  },
  {
    label: 'Events',
    link: '/events',
  },
];

export default navigation;
